import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import Layout from '../components/layout';
import Breadcrumbs from '../components/breadcrumbs';
import Cta from '../components/cta';
import { eventTransform } from '../utils/cloudinary';

const CurrentNeeds = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0,0); // fix Gatsby Link retaining scroll position
  }, []);

  // TODO: find a way to get the alt for the photo
  const photo = data.needs.photo;
  const photoURL = eventTransform(photo.secure_url);

  return (
    <Layout>
      <main id="main">
        <Breadcrumbs title={data.needs.title} />

        <section className="current-needs">
          <div className="container">
            <div className="row gy-4">

              <div className="col-lg-4">
                <div className="info">
                  <h3>Current Needs:</h3>
                  <h2>{ data.needs.title }</h2>
                  <BlockContent blocks={data.needs.description} className="description" />
                  <Cta
                    type={data.needs.cta.type}
                    value={(
                      data.needs.cta.type === 'email' ? data.needs.cta.target.email : data.needs.cta.value
                    )}>{data.needs.cta.type !== 'email' ? data.needs.cta.text : ``}</Cta>
                </div>
              </div>
              <div className="col-lg-8">
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img src={photoURL} alt={photo.context?.custom?.alt} />
              </div>

            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  {
    needs: sanityNeeds {
      title
      description: _rawDescription
      photo {
        secure_url
        context {
          custom {
            alt
          }
        }
      }
      cta {
        value
        type
        text
        target {
          email
        }
      }
    }
  }
`

export default CurrentNeeds;
