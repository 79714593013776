import React from 'react';

const Breadcrumbs = ({ title }) => (
    <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2 style={ { visibility: 'hidden' } }>{title}</h2>
          <ol>
            <li><a href="/">Home</a></li>
            <li>{ title }</li>
          </ol>
        </div>

      </div>
    </section>
);

export default Breadcrumbs;
